import './App.css';
import numeral from 'numeral';

import { useState, useEffect, useMemo } from 'react';

function App() {
  const [LBData, setLBData] = useState([]);
  const [LBData2, setLBData2] = useState([]);
  const [LBData3, setLBData3] = useState([]);

  
  const avatar2 = (account) => {
    if (!account.avatar) return "https://clash.gg/assets/csgo/avatar-anonymous.png";
    if (account.avatar.includes("steamstatic.com")) return account.avatar.split(".jpg")[0] + ".jpg"
    if (account.avatar.includes("/assets/csgo/avatar-anonymous.png" )) return "https://clash.gg/assets/csgo/avatar-anonymous.png"
    else return account.avatar
  }

  function getNextFirstOfMonth() {
    const now = new Date();
  
    // Get the first day of the next month
    const nextMonth = now.getUTCMonth() + 1; // Get the next month (0-based index)
    const firstDayOfNextMonth = new Date(Date.UTC(now.getUTCFullYear(), nextMonth, 1));
  
    // Set the time to 23:59:00 UTC on the 1st of the next month
    firstDayOfNextMonth.setUTCHours(23, 59, 0, 0); 
  
    // Format the date to IDT timezone using Intl.DateTimeFormat
    const options = {
      timeZone: 'Asia/Jerusalem',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // 24-hour time format
    };
    const idtDate = new Intl.DateTimeFormat('en-CA', options).format(firstDayOfNextMonth);
  
    return idtDate; // Returns formatted date and time in IDT
  }
  
  const [LBTab, setLBTab] = useState('Clash');
    const [expiryTime2, setExpiryTime2] = useState(getNextFirstOfMonth());

    const [countdownTime2, setCountdownTime2] = useState({
      countdownDays: "",
      countdownHours: "",
      countdownMinutes: "",
      countdownSeconds: "",
    });
    
    const countdownTimer2 = () => {
      const timeInterval = setInterval(() => {
        const countdownDateTime = new Date(expiryTime2).getTime();
        const currentTime = new Date().getTime();
        const remainingDayTime = countdownDateTime - currentTime;
        const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
        const totalHours = Math.floor(
          (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const totalMinutes = Math.floor(
          (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const totalSeconds = Math.floor(
          (remainingDayTime % (1000 * 60)) / 1000
        );
    
        const runningCountdownTime = {
          countdownDays: totalDays,
          countdownHours: totalHours,
          countdownMinutes: totalMinutes,
          countdownSeconds: totalSeconds,
        };
    
        setCountdownTime2(runningCountdownTime);
    
        if (remainingDayTime < 0) {
          clearInterval(timeInterval);
          setExpiryTime2(false);
        }
      }, 1000);
    };

    const [expiryTime, setExpiryTime] = useState(getNextFirstOfMonth());
const [countdownTime, setCountdownTime] = useState({
  countdownDays: "",
  countdownHours: "",
  countdownMinutes: "",
  countdownSeconds: "",
});




const countdownTimer = () => {
  const timeInterval = setInterval(() => {
    const countdownDateTime = new Date(expiryTime).getTime();
    const currentTime = new Date().getTime();
    const remainingDayTime = countdownDateTime - currentTime;
    const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
    const totalHours = Math.floor(
      (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const totalMinutes = Math.floor(
      (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
    );
    const totalSeconds = Math.floor(
      (remainingDayTime % (1000 * 60)) / 1000
    );

    const runningCountdownTime = {
      countdownDays: totalDays,
      countdownHours: totalHours,
      countdownMinutes: totalMinutes,
      countdownSeconds: totalSeconds,
    };

    setCountdownTime(runningCountdownTime);

    if (remainingDayTime < 0) {
      clearInterval(timeInterval);
      setExpiryTime(false);
    }
  }, 1000);
};


useEffect(() => {
  countdownTimer();
  countdownTimer2();
});



    const pricesClash = [1000,250,250,100,100,100,100,100,0,0];
    const prices = [500,250,250,100,100,100,100,100,0,0];
    useMemo(() => {
      fetch("https://api.psyqrleadboard.com/clash", {

      })
        .then((response) => response.json())
        .then((data) => {

          data.sort((a, b) => parseFloat(b.wagered) - parseFloat(a.wagered));
          data = data.slice(0, 10);
          
          setLBData(data);
        })
        .catch((error) => console.log(error));
    }, []);
    /*useMemo(() => {
      fetch("https://api.psyqrleadboard.com/raingg", {

      })
        .then((response) => response.json())
        .then((data) => {
          data.sort((a, b) => parseFloat(b.wagered) - parseFloat(a.wagered));
          data = data.slice(0, 10);
          setLBData2(data);
        })
        .catch((error) => console.log(error));
    }, []);*/

    useMemo(() => {
      fetch("https://api.psyqrleadboard.com/csgobig", {

      })
        .then((response) => response.json())
        .then((data) => {
          data.sort((a, b) => parseFloat(b.wagerTotal) - parseFloat(a.wagerTotal));
          data = data.slice(0, 10);
          setLBData3(data);
        })
        .catch((error) => console.log(error));
    }, []);
    
  return (
    <>
    <header>
  <nav>
    <div className="logo-title" >
      <img src="https://yt3.googleusercontent.com/ytc/AIdro_mCn3ax93ezH8RJXzSTFmoKmnnvfc_7rybrJNFWLPxvGog=s176-c-k-c0x00ffffff-no-rj-mo" className="logo" />
      <h1 className="title">QR</h1>
    </div>
    <ul className="nav-links"></ul>
  </nav>
</header>
<h1
    className="rewards-btn  clash-image"
    id="clashImage"
    style={{ cursor: "pointer", opacity: LBTab == 'Rewards' ? 1 : 0.3 }}
    onClick={()=>{ setLBTab('Rewards')}}

  >Rewards</h1>
<div className="toggle-images" style={{ marginTop: 50 }} bis_skin_checked={1}>
  <img
    className="game-image clash-image"
    id="clashImage"
    src={process.env.PUBLIC_URL + '/img/clash-logo.png'}
    style={{ cursor: "pointer", opacity: LBTab == 'Clash' ? 1 : 0.3 }}
    onClick={()=>{ setLBTab('Clash')}}
  />
{/* <img
    className="game-image clash-image"
    id="clashImage"
    src={process.env.PUBLIC_URL + '/img/rain.png'}
    style={{ cursor: "pointer", opacity: LBTab == 'RainGG' ? 1 : 0.3 }}
    onClick={()=>{ setLBTab('RainGG')}}
  />
    <div className="vertical-line" bis_skin_checked={1} />

  */}
  <div className="vertical-line" bis_skin_checked={1} />
  <img
    className="game-image clash-image"
    id="clashImage"
    src="https://csgobig.com/assets/img/logo-new.png"
    style={{ cursor: "pointer", opacity: LBTab == 'CSGOBig' ? 1 : 0.3 }}
    onClick={()=>{ setLBTab('CSGOBig')}}
  />
</div>


<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>
<div className="snowflake"></div>

{LBTab == 'Clash' && (
<div data-v-1d580398="" data-v-e4097664="" id="appContent">
<img className='img33' src={process.env.PUBLIC_URL + '/img/clash-left-bg.webp'} />
  <img className='img34' src={process.env.PUBLIC_URL + '/img/clash-right-bg.webp'} />
    <div data-v-1d580398="">
      <div className="css-esk2ah">
      <div className="css-2w2ovy">
          <div className="css-gqrafh">
            <div className="css-jehefp">
            {LBData.map((student, index) => ( index == 1 ? (
              <img
                srcSet={avatar2(student)}
                width={96}
                height={96}
                decoding="async"
                data-nimg={1}
                className="css-1wgwpc8"
                loading="lazy"
                style={{ color: "transparent", animation: 'pulse-silver 1s infinite' }}
              />
              ) : null ))}
              <div className="css-hca0vm">
              {LBData.map((student, index) => ( index == 1 ? (
                   <span className="css-15a1lq3">{student.name}</span>) : null
            ))}
                
              </div>
              <div className="css-7ahevu ejrykqo0">
                <span className="css-1vqddgv">Wagered </span>
                <span className="css-18icuxn">
                  <div className="css-1y0ox2o">
                    {LBData.map((student, index) => ( index == 1 ? (
                   <span className="css-114dvlx">{numeral(student.wagered / 100).format('0,0.00')}</span>) : null
            ))}
                  </div>
                </span>
              </div>
              <span className="css-v4675v">
                <div className="css-1y0ox2o">
                  <div
                    data-v-1d580398=""
                    className="price-wrapper"
                    style={{ color: "#FFF" }}
                  >
                    <div className="price-image-wrapper">
                      <img className='gem' src={process.env.PUBLIC_URL + '/img/gem.svg'} />
                    </div>
                    {prices[1]}
                  </div>
                </div>
              </span>
            </div>
            <div className="css-oijls1">
              {LBData.map((student, index) => ( index == 0 ? (
              <img
              srcSet={avatar2(student)}
                width={96}
                height={96}
                decoding="async"
                data-nimg={1}
                className="css-1wgwpc8"
                loading="lazy"
                style={{ color: "transparent", animation: 'pulse-gold 1s infinite' }}
              />
              ) : null ))}
              <div className="css-hca0vm">
                
                {LBData.map((student, index) => ( index == 0 ? (
                   <span className="css-15a1lq3">{student.name}</span>) : null
            ))}
              </div>
              <div className="css-7ahevu ejrykqo0">
                <span className="css-1vqddgv">Wagered </span>
                <span className="css-18icuxn">
                  <div className="css-1y0ox2o">
                  {LBData.map((student, index) => ( index == 0 ? (
                   <span className="css-114dvlx">{numeral(student.wagered / 100).format('0,0.00')}</span>) : null
                ))}
                  </div>
                </span>
              </div>
              <span className="css-v4675v">
                <div className="css-1y0ox2o">
                  <div
                    data-v-1d580398=""
                    className="price-wrapper"
                    style={{ color: "#FFF" }}
                  >
                    <div className="price-image-wrapper">
                      <img className='gem' src={process.env.PUBLIC_URL + '/img/gem.svg'} />
                    </div>
                    {pricesClash[0]}
                  </div>
                </div>
              </span>
            </div>
            <div className="css-jehefp">
            {LBData.map((student, index) => ( index == 2 ? (
              <img
              srcSet={avatar2(student)}
                width={96}
                height={96}
                decoding="async"
                data-nimg={1}
                className="css-1wgwpc8"
                loading="lazy"
                style={{ color: "transparent", animation: 'pulse-bronze 1s infinite' }}
              />
              ) : null ))}

              <div className="css-hca0vm">
              {LBData.map((student, index) => ( index == 2 ? (
                   <span className="css-15a1lq3">{student.name}</span>) : null
            ))}
              </div>
              <div className="css-7ahevu ejrykqo0">
                <span className="css-1vqddgv">Wagered </span>
                <span className="css-18icuxn">
                  <div className="css-1y0ox2o">
                  {LBData.map((student, index) => ( index == 2 ? (
                   <span className="css-114dvlx">{numeral(student.wagered / 100).format('0,0.00')}</span>) : null
                ))}
                  </div>
                </span>
              </div>
              <span className="css-v4675v">
                <div className="css-1y0ox2o">
                  <div
                    data-v-1d580398=""
                    className="price-wrapper"
                    style={{ color: "#FFF" }}
                  >
                    <div className="price-image-wrapper">
                      <img className='gem' src={process.env.PUBLIC_URL + '/img/gem.svg'} />
                    </div>
                    {prices[2]}
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div data-v-1d580398="" className="countdown">
            <div data-v-1d580398="">
              <span data-v-1d580398="" className="title">
                Leaderboard ends in{" "}
              </span>
              <span data-v-1d580398="" className="subtitle" id="countdown">
                {countdownTime.countdownDays} Days {countdownTime.countdownHours}:{countdownTime.countdownMinutes}:{countdownTime.countdownSeconds}
              </span>
            </div>
          </div>

        </div>

        <div data-v-1d580398="" className="row text">
        <span data-v-1d580398="" className="title">
          <div data-v-1d580398="" className="price-wrapper glow">
            <div className="price-image-wrapper">
              <img
                src={process.env.PUBLIC_URL + '/img/gem.svg'}
                className="gem"
                style={{ top: 3, height: 30, width: 30, left: 0 }}
              />
            </div>{" "}
            2000
          </div>{" "}
          <br /><span className="glow">Monthly</span> Clash Leaderboard!
        </span>
        <span>
        ALL PRIZES ARE IN CLASH’S CURRENCY
        </span>
        <br />
        <span>Code: QR</span>
        <span data-v-1d580398="" className="subtitle" style={{ color: "#FFF" }}>
        </span>
      </div>
      <br />
      <div className="container" style={{ maxWidth: '80%' }}>
  <div data-v-1d580398="" className="row list details" bis_skin_checked={1}>
    <div data-v-1d580398="" className="hide-mobile col-2" bis_skin_checked={1}>
      Place
    </div>
    <div data-v-1d580398="" className="col-5" bis_skin_checked={1}>
      Player
    </div>
    <div data-v-1d580398="" className="col-2" bis_skin_checked={1}>
      Reward
    </div>
    <div data-v-1d580398="" className="col-3" bis_skin_checked={1}>
      Wagered
    </div>
  </div>
  {LBData.map((user, index) => {
    if (index < 3) return <></>;
    return (
      <div data-v-1d580398="" className="row list row-cols-5" bis_skin_checked={1}>
        <div data-v-1d580398="" className="hide-mobile col-2" bis_skin_checked={1}>
          <b>{index + 1}th</b>
        </div>
        <div data-v-1d580398="" className="col-5" bis_skin_checked={1}>
          <span>{user.name}</span>
        </div>
        <div data-v-1d580398="" className="col-2" bis_skin_checked={1}>
          <div className="price-wrapper prize-label">
            <div className="price-image-wrapper2">
              <img className="gem" src={process.env.PUBLIC_URL + '/img/gem.svg'} />
            </div>
            {prices[index]}
          </div>
        </div>
        <div data-v-1d580398="" className="col-3 wagerlabel" bis_skin_checked={1}>
          <div className="price-wrapper" style={{ color: "#eee" }}>
            <div className="price-image-wrapper" style={{ height: "0rem", width: "0rem", marginRight: 0 }} />
            <div className="price-image-wrapper3">
              <img className="gem" src={process.env.PUBLIC_URL + '/img/gem.svg'} />
            </div>
            {numeral(user.wagered / 100).format('0,0.00')}
          </div>
        </div>
      </div>
    );
  })}
</div>

        
        

     
      </div>
    </div>
  </div>)}

  { /* {LBTab == 'RainGG' && (
    <div data-v-1d580398="" data-v-e4097664="" id="appContent">
      <img className='img35' src={process.env.PUBLIC_URL + "/img/rain-left-bg.webp"} />
      <img className='img36' src={process.env.PUBLIC_URL + "/img/rain-right-bg.webp"} />
        <div data-v-1d580398="">
          <div className="css-esk2ah">
          <div className="css-2w2ovy">
              <div className="css-gqrafh">
                <div className="css-jehefp">
                {LBData2.map((student, index) => ( index == 1 ? (
                  <img
                    srcSet={avatar2(student)}
                    width={96}
                    height={96}
                    decoding="async"
                    data-nimg={1}
                    className="css-1wgwpc8"
                    loading="lazy"
                    style={{ color: "transparent", animation: 'pulse-silver 1s infinite' }}
                  />
                  ) : null ))}
                  <div className="css-hca0vm">
                  {LBData2.map((student, index) => ( index == 1 ? (
                       <span className="css-15a1lq3">{student.username}</span>) : null
                ))}
                    
                  </div>
                  <div className="css-7ahevu ejrykqo0">
                    <span className="css-1vqddgv">Wagered </span>
                    <span className="css-18icuxn">
                      <div className="css-1y0ox2o">
                        {LBData2.map((student, index) => ( index == 1 ? (
                       <span className="css-114dvlx">{numeral(student.wagered).format('0,0')}</span>) : null
                ))}
                      </div>
                    </span>
                  </div>
                  <span className="css-v4675v">
                    <div className="css-1y0ox2o">
                      <div
                        data-v-1d580398=""
                        className="price-wrapper"
                        style={{ color: "#FFF" }}
                      >
                        <div className="price-image-wrapper">
                          <img className='gem' src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                        </div>
                        {prices[1]}
                      </div>
                    </div>
                  </span>
                </div>
                <div className="css-oijls1">
                  {LBData2.map((student, index) => ( index == 0 ? (
                  <img
                  srcSet={avatar2(student)}
                    width={96}
                    height={96}
                    decoding="async"
                    data-nimg={1}
                    className="css-1wgwpc8"
                    loading="lazy"
                    style={{ color: "transparent", animation: 'pulse-gold 1s infinite' }}
                  />
                  ) : null ))}
                  <div className="css-hca0vm">
                    
                    {LBData2.map((student, index) => ( index == 0 ? (
                       <span className="css-15a1lq3">{student.username}</span>) : null
                ))}
                  </div>
                  <div className="css-7ahevu ejrykqo0">
                    <span className="css-1vqddgv">Wagered </span>
                    <span className="css-18icuxn">
                      <div className="css-1y0ox2o">
                      {LBData2.map((student, index) => ( index == 0 ? (
                       <span className="css-114dvlx">{numeral(student.wagered).format('0,0')}</span>) : null
                    ))}
                      </div>
                    </span>
                  </div>
                  <span className="css-v4675v">
                    <div className="css-1y0ox2o">
                      <div
                        data-v-1d580398=""
                        className="price-wrapper"
                        style={{ color: "#FFF" }}
                      >
                        <div className="price-image-wrapper">
                          <img className='gem' src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                        </div>
                        {prices[0]}
                      </div>
                    </div>
                  </span>
                </div>
                <div className="css-jehefp">
                {LBData2.map((student, index) => ( index == 2 ? (
                  <img
                  srcSet={avatar2(student)}
                    width={96}
                    height={96}
                    decoding="async"
                    data-nimg={1}
                    className="css-1wgwpc8"
                    loading="lazy"
                    style={{ color: "transparent", animation: 'pulse-bronze 1s infinite' }}
                  />
                  ) : null ))}
    
                  <div className="css-hca0vm">
                  {LBData2.map((student, index) => ( index == 2 ? (
                       <span className="css-15a1lq3">{student.username}</span>) : null
                ))}
                  </div>
                  <div className="css-7ahevu ejrykqo0">
                    <span className="css-1vqddgv">Wagered </span>
                    <span className="css-18icuxn">
                      <div className="css-1y0ox2o">
                      {LBData2.map((student, index) => ( index == 2 ? (
                       <span className="css-114dvlx">{numeral(student.wagered).format('0,0')}</span>) : null
                    ))}
                      </div>
                    </span>
                  </div>
                  <span className="css-v4675v">
                    <div className="css-1y0ox2o">
                      <div
                        data-v-1d580398=""
                        className="price-wrapper"
                        style={{ color: "#FFF" }}
                      >
                        <div className="price-image-wrapper">
                          <img className='gem' src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                        </div>
                        {prices[2]}
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div data-v-1d580398="" className="countdown">
                <div data-v-1d580398="">
                  <span data-v-1d580398="" className="title">
                    Leaderboard ends in{" "}
                  </span>
                  <span data-v-1d580398="" className="subtitle" id="countdown">
                    {countdownTime.countdownDays} Days {countdownTime.countdownHours}:{countdownTime.countdownMinutes}:{countdownTime.countdownSeconds}
                  </span>
                </div>
              </div>
    
            </div>
    
            <div data-v-1d580398="" className="row text">
            <span data-v-1d580398="" className="title">
              <div data-v-1d580398="" className="price-wrapper glow">
                <div className="price-image-wrapper">
                  <img
                    src={process.env.PUBLIC_URL + '/img/rain-coin.svg'}
                    className="gem"
                    style={{ top: 3, height: 30, width: 30, left: 0 }}
                  />
                </div>{" "}
                1500
              </div>{" "}
              <br /><span className="glow">Monthly</span> Clash Leaderboard!
            </span>
            <span>
            ALL PRIZES ARE IN RAINGG’S CURRENCY
            </span>
            <br />
            <span>Code: QR</span>
            <span data-v-1d580398="" className="subtitle" style={{ color: "#FFF" }}>
            </span>
          </div>
          <br />
          <div className="container" style={{ maxWidth: '80%' }}>
      <div data-v-1d580398="" className="row list details" bis_skin_checked={1}>
        <div data-v-1d580398="" className="hide-mobile col-2" bis_skin_checked={1}>
          Place
        </div>
        <div data-v-1d580398="" className="col-5" bis_skin_checked={1}>
          Player
        </div>
        <div data-v-1d580398="" className="col-2" bis_skin_checked={1}>
          Reward
        </div>
        <div data-v-1d580398="" className="col-3" bis_skin_checked={1}>
          Wagered
        </div>
      </div>
      {LBData2.map((user, index) => {
        if (index < 3) return <></>;
        return (
          <div data-v-1d580398="" className="row list row-cols-5" bis_skin_checked={1}>
            <div data-v-1d580398="" className="hide-mobile col-2" bis_skin_checked={1}>
              <b>{index + 1}th</b>
            </div>
            <div data-v-1d580398="" className="col-5" bis_skin_checked={1}>
              <span>{user.username}</span>
            </div>
            <div data-v-1d580398="" className="col-2" bis_skin_checked={1}>
              <div className="price-wrapper prize-label">
                <div className="price-image-wrapper2">
                  <img className="gem" src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                </div>
                {prices[index]}
              </div>
            </div>
            <div data-v-1d580398="" className="col-3 wagerlabel" bis_skin_checked={1}>
              <div className="price-wrapper" style={{ color: "#eee" }}>
                <div className="price-image-wrapper" style={{ height: "0rem", width: "0rem", marginRight: 0 }} />
                <div className="price-image-wrapper3">
                  <img className="gem" src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                </div>
                {numeral(user.wagered).format('0,0')}
              </div>
            </div>
          </div>
        );
      })}
    </div>
    
            
            
    
         
          </div>
        </div>
      </div>)}*/}




{LBTab == 'CSGOBig' && (
        <div data-v-1d580398="" data-v-e4097664="" id="appContent">
<img className='img33 rainimg' src="https://www.betjuicy.com/leaderboard/csgobig-left-bg.webp" />
<img className='img37 rainimg' src="https://www.betjuicy.com/leaderboard/csgobig-right-bg.webp" />
          <div data-v-1d580398="">
            <div className="css-esk2ah">
            <div className="css-2w2ovy">
                <div className="css-gqrafh">
                  <div className="css-jehefp">
                  {LBData3.map((student, index) => ( index == 1 ? (
                    <img
                      srcSet={avatar2(student)}
                      width={96}
                      height={96}
                      decoding="async"
                      data-nimg={1}
                      className="css-1wgwpc8"
                      loading="lazy"
                      style={{ color: "transparent", animation: 'pulse-silver 1s infinite' }}
                    />
                    ) : null ))}
                    <div className="css-hca0vm">
                    {LBData3.map((student, index) => ( index == 1 ? (
                         <span className="css-15a1lq3">{student.name}</span>) : null
                  ))}
                      
                    </div>
                    <div className="css-7ahevu ejrykqo0">
                      <span className="css-1vqddgv">Wagered </span>
                      <span className="css-18icuxn">
                        <div className="css-1y0ox2o">
                          {LBData3.map((student, index) => ( index == 1 ? (
                         <span className="css-114dvlx">{numeral(student.wagerTotal).format('0,0')}</span>) : null
                  ))}
                        </div>
                      </span>
                    </div>
                    <span className="css-v4675v">
                      <div className="css-1y0ox2o">
                        <div
                          data-v-1d580398=""
                          className="price-wrapper"
                          style={{ color: "#FFF" }}
                        >
                          <div className="price-image-wrapper">
                            <img className='gem' src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                          </div>
                          {prices[1]}
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="css-oijls1">
                    {LBData3.map((student, index) => ( index == 0 ? (
                    <img
                    srcSet={avatar2(student)}
                      width={96}
                      height={96}
                      decoding="async"
                      data-nimg={1}
                      className="css-1wgwpc8"
                      loading="lazy"
                      style={{ color: "transparent", animation: 'pulse-gold 1s infinite' }}
                    />
                    ) : null ))}
                    <div className="css-hca0vm">
                      
                      {LBData3.map((student, index) => ( index == 0 ? (
                         <span className="css-15a1lq3">{student.name}</span>) : null
                  ))}
                    </div>
                    <div className="css-7ahevu ejrykqo0">
                      <span className="css-1vqddgv">Wagered </span>
                      <span className="css-18icuxn">
                        <div className="css-1y0ox2o">
                        {LBData3.map((student, index) => ( index == 0 ? (
                         <span className="css-114dvlx">{numeral(student.wagerTotal).format('0,0')}</span>) : null
                      ))}
                        </div>
                      </span>
                    </div>
                    <span className="css-v4675v">
                      <div className="css-1y0ox2o">
                        <div
                          data-v-1d580398=""
                          className="price-wrapper"
                          style={{ color: "#FFF" }}
                        >
                          <div className="price-image-wrapper">
                            <img className='gem' src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                          </div>
                          {prices[0]}
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="css-jehefp">
                  {LBData3.map((student, index) => ( index == 2 ? (
                    <img
                    srcSet={avatar2(student)}
                      width={96}
                      height={96}
                      decoding="async"
                      data-nimg={1}
                      className="css-1wgwpc8"
                      loading="lazy"
                      style={{ color: "transparent", animation: 'pulse-bronze 1s infinite' }}
                    />
                    ) : null ))}
      
                    <div className="css-hca0vm">
                    {LBData3.map((student, index) => ( index == 2 ? (
                         <span className="css-15a1lq3">{student.name}</span>) : null
                  ))}
                    </div>
                    <div className="css-7ahevu ejrykqo0">
                      <span className="css-1vqddgv">Wagered </span>
                      <span className="css-18icuxn">
                        <div className="css-1y0ox2o">
                        {LBData3.map((student, index) => ( index == 2 ? (
                         <span className="css-114dvlx">{numeral(student.wagerTotal).format('0,0')}</span>) : null
                      ))}
                        </div>
                      </span>
                    </div>
                    <span className="css-v4675v">
                      <div className="css-1y0ox2o">
                        <div
                          data-v-1d580398=""
                          className="price-wrapper"
                          style={{ color: "#FFF" }}
                        >
                          <div className="price-image-wrapper">
                            <img className='gem' src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                          </div>
                          {prices[2]}
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div data-v-1d580398="" className="countdown">
                  <div data-v-1d580398="">
                    <span data-v-1d580398="" className="title">
                      Leaderboard ends in{" "}
                    </span>
                    <span data-v-1d580398="" className="subtitle" id="countdown">
                      {countdownTime.countdownDays} Days {countdownTime.countdownHours}:{countdownTime.countdownMinutes}:{countdownTime.countdownSeconds}
                    </span>
                  </div>
                </div>
      
              </div>
      
              <div data-v-1d580398="" className="row text">
              <span data-v-1d580398="" className="title">
                <div data-v-1d580398="" className="price-wrapper glow">
                  <div className="price-image-wrapper">
                    <img
                      src={process.env.PUBLIC_URL + '/img/rain-coin.svg'}
                      className="gem"
                      style={{ top: 3, height: 30, width: 30, left: 0 }}
                    />
                  </div>{" "}
                  1500
                </div>{" "}
                <br /><span className="glow">Monthly</span> Clash Leaderboard!
              </span>
              <span>
              ALL PRIZES ARE IN CSGOBIG’S CURRENCY
              </span>
              <br />
              <span>Code: QR</span>
              <span data-v-1d580398="" className="subtitle" style={{ color: "#FFF" }}>
              </span>
            </div>
            <br />
            <div className="container" style={{ maxWidth: '80%' }}>
        <div data-v-1d580398="" className="row list details" bis_skin_checked={1}>
          <div data-v-1d580398="" className="hide-mobile col-2" bis_skin_checked={1}>
            Place
          </div>
          <div data-v-1d580398="" className="col-5" bis_skin_checked={1}>
            Player
          </div>
          <div data-v-1d580398="" className="col-2" bis_skin_checked={1}>
            Reward
          </div>
          <div data-v-1d580398="" className="col-3" bis_skin_checked={1}>
            Wagered
          </div>
        </div>
        {LBData3.map((user, index) => {
          if (index < 3) return <></>;
          return (
            <div data-v-1d580398="" className="row list row-cols-5" bis_skin_checked={1}>
              <div data-v-1d580398="" className="hide-mobile col-2" bis_skin_checked={1}>
                <b>{index + 1}th</b>
              </div>
              <div data-v-1d580398="" className="col-5" bis_skin_checked={1}>
                <span>{user.name}</span>
              </div>
              <div data-v-1d580398="" className="col-2" bis_skin_checked={1}>
                <div className="price-wrapper prize-label">
                  <div className="price-image-wrapper2">
                    <img className="gem" src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                  </div>
                  {prices[index]}
                </div>
              </div>
              <div data-v-1d580398="" className="col-3 wagerlabel" bis_skin_checked={1}>
                <div className="price-wrapper" style={{ color: "#eee" }}>
                  <div className="price-image-wrapper" style={{ height: "0rem", width: "0rem", marginRight: 0 }} />
                  <div className="price-image-wrapper3">
                    <img className="gem" src={process.env.PUBLIC_URL + '/img/rain-coin.svg'} />
                  </div>
                  {numeral(user.wagerTotal).format('0,0')}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      
              
              
      
           
            </div>
          </div>
        </div>)}


{LBTab == 'Rewards' && (
  <div data-v-1d580398="" data-v-e4097664="" id="appContent" className="reward-container">
    <a href="https://clash.gg/r/QR" target="_blank" rel="noopener noreferrer">      
      <img
          className="reward-image"
          src={process.env.PUBLIC_URL + '/img/clashgg_rewards.jpeg'}
          alt="Rewards"
      />
    </a>
    <a href="https://rain.gg/r/QR" target="_blank" rel="noopener noreferrer">
      <img
          className="reward-image"
          src={process.env.PUBLIC_URL + '/img/rain_rewards.jpeg'}
          alt="Another Image"
      />
    </a>
    <a href="https://csgobig.com/r/qr" target="_blank" rel="noopener noreferrer">
      <img
          className="reward-image"
          src={process.env.PUBLIC_URL + '/img/csgobig-reward.png'}
          alt="Another Image"
      />
    </a>
    <a href="https://csgoroll.com/r/QR" target="_blank" rel="noopener noreferrer">
      <img
          className="reward-image"
          src={process.env.PUBLIC_URL + '/img/csgorail-rewards.png'}
          alt="Another Image"
      />
    </a>

  </div>
)}






    </>

  );
}

export default App;
